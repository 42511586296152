import React, { useCallback, useState, useEffect } from 'react';
import { Link as RouterLink, useLocation, matchPath, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, Toolbar, Grid, Button, Divider } from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';
import AccountPopover from './AccountPopover';
import Logo from '../../images/logo.png';
import api from '../../lib/axios';


const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText,
    borderBottom: `2px solid #D5D5D5`,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none'
  }),
  // zIndex: theme.zIndex.drawer + 100
}));


const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const [sites, setSites] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const location = useLocation()
  const naviate = useNavigate()

  const getSites = useCallback(async () => {
    try {
      const res = await api.get('/apis/companysites/byCompany/')

      if (res.data.success) {
        var data = res.data.data
        if (!localStorage.getItem("timeZone") || localStorage.getItem("timeZone") === undefined || localStorage.getItem("timeZone") === "") {
          localStorage.setItem("timeZone", data[0].timeZone)
        }

        if (!localStorage.getItem("siteID") || localStorage.getItem("siteID") === undefined || localStorage.getItem("siteID") === "") {
          localStorage.setItem("siteID", data[0]._id)
          window.location.href = "/"
        }
        setSites(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("role") != "Standard") {
      getSites();
    }
  },
    []);


  const navigationOptions = [
    {
      title: 'Dashboard',
      path: '/dashboard',
    },
    {
      title: 'Work Orders',
      path: '/workOrder',
    },

    // {
    //   title: 'Production',
    //   path: '/dashboard2',
    // },
    {
      title: 'Trailer Inventory',  // Inventory (Inventory name change)
      path: '/inventory',
    },
    {
      title: 'Equipment & Parts Inventory',    //    Raw Materials (Raw Materials name change)
      path: '/rawMaterials',
    },
    {
      title: 'Used Equipment',    //    Raw Materials (Raw Materials name change)
      path: '/usedEquipments',
    },
    {
      title: 'Reporting',    //    Raw Materials (Raw Materials name change)
      path: '/reporting',
    },
  ];

  return (
    <DashboardNavbarRoot {...other} sx={{ backgroundColor: '#F8F8FA' }}>
      <Toolbar>
        {/* 
        <IconButton color="inherit" onClick={onSidebarMobileOpen} size="large">
            <MenuIcon fontSize="small" />
          </IconButton> */}


        {/* <Box
          sx={{
            flexGrow: 2,
            ml: 2
          }}
        /> */}
        <Grid
          container
          direction="row"
          justifyContent={"flex-start"}
          alignContent={"center"}
          height='64px'
        >
          <Grid
            mr={5}
            item
            alignItems={"center"}
            display={"flex"}

          >
            <RouterLink to="/">
              <img
                style={{

                }}
                src={Logo}
                alt="Titan Logo"
                height="30"
              />
            </RouterLink>

          </Grid>
          {location.pathname.toLowerCase().includes('/mobileview') ?
            <Box sx={{ mr: 4 }} justifyContent='space-between' height='100%' display={"flex"} flexDirection="column">
              <div></div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                  style={{
                    width: '10px',
                    height: '10px',
                    borderRadius: '50%',
                    backgroundColor: location.pathname.toLowerCase().includes('/mobileview') ? '#E83880' : '#51A2F7',
                    marginRight: '8px',
                  }}
                />
                <div>
                  <Button
                    onClick={(e) => {
                      naviate('/mobileview')

                    }}
                    sx={{
                      color: '#262B40',
                      fontSize: '15px',
                      fontFamily: 'roboto',
                      fontWeight: 'normal',
                    }}>
                    Mobile View
                  </Button>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                {location.pathname.toLowerCase().toLowerCase().includes('/mobileview') && <Divider sx={{ height: "3px", width: "75%", mr: 1 }} color="#0875E1" />}

              </div>
            </Box> :
            navigationOptions.map((route, index) => (
              <Box key={index} sx={{ mr: 4 }} justifyContent='space-between' height='100%' display={"flex"} flexDirection="column">
                <div></div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div
                    style={{
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      backgroundColor: (location.pathname.includes(route.path) || (route.path == '/dashboard' && location.pathname === '/')) ? '#E83880' : '#51A2F7',
                      marginRight: '8px',
                    }}
                  />
                  <div>
                    <Button
                      onClick={(e) => {
                        localStorage.setItem("selectedIndex", index)
                        localStorage.setItem("partTab", "rawMaterial")
                        localStorage.setItem("reportTab", "workOrderReport")
                        setSelectedIndex(index)
                        naviate(route.path)

                      }}
                      sx={{
                        color: '#262B40',
                        fontSize: '15px',
                        fontFamily: 'roboto',
                        fontWeight: 'normal',
                      }}>
                      {route.title}
                    </Button>
                  </div>
                </div>
                <div style={{ display: 'flex', justifyContent: 'right' }}>
                  {(location.pathname.includes(route.path) || (route.path == '/dashboard' && location.pathname === '/')) && <Divider sx={{ height: "3px", width: "75%", mr: 1 }} color="#0875E1" />}
                  {/* { "" + localStorage.getItem("selectedIndex") === "" + index && <Divider sx={{ height: "3px", width: "100%" }} color="#0875E1" />} */}
                </div>
              </Box>
            ))
          }
        </Grid>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2
          }}
        />
        <Box sx={{ ml: 2 }}>
          {/* {(localStorage.getItem("isLogin") == "true") && (<AccountPopover />)} */}
          <AccountPopover sites={sites} />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
