import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Box, Button, FormHelperText, TextField, Card, CardContent, Container, Typography, Divider, Link } from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import { Link as RouterLink } from 'react-router-dom';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { Helmet } from 'react-helmet-async';
import Logo from '../../../images/logo.png';
import axios from 'axios';
import { useSnackbar } from 'notistack'
const ForgotPassForm = (props) => {
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const apiCalling = async (email) => {
    var data = {
      email: email,
    }
    var obj = await axios.post('/apis/CompanyLogin/forgotPassword/', data)
    return obj.data
  }

  return (
    <>
      <Helmet>
        <title>Forgot Password | Titan</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "#f5f3f2",
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Container
          maxWidth="sm"
          sx={{ py: '80px' }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 8
            }}
          >
            <img
              src={Logo}
              alt="Logo"
              height="70"
            />
          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3
                }}
              >
                <div>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                  >
                    Forgot Password?
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    Enter registered email to get link
                  </Typography>
                </div>

              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3
                }}
              >
                <Formik
                  initialValues={{
                    email: '',
                    submit: null
                  }}
                  validationSchema={Yup
                    .object()
                    .shape({
                      email: Yup.string().email().required('E-Mail is required').email('Must be a valid email')
                    })}
                  onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                      var data = await apiCalling(values.email);
                      if (data !== undefined && data.success && isMountedRef.current) {
                        props.navigate("/authentication/login")
                        enqueueSnackbar(data.message, {
                          anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                          },
                          variant: 'success'
                        });
                        setStatus({ success: true });
                        setSubmitting(false);
                      } else {
                        enqueueSnackbar(data.message, {
                          anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                          },
                          variant: 'error'
                        });
                        setStatus({ success: false });
                        setErrors({ submit: data.message });
                        setSubmitting(false);
                      }

                    } catch (err) {
                      console.log("checkEmail:inCATCH");
                      console.error(err);
                      if (isMountedRef.current) {
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                      }
                    }
                  }}
                >
                  {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form
                      noValidate
                      onSubmit={handleSubmit}
                      {...props}
                    >

                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        helperText={touched.email && errors.email}
                        label="Registered email"
                        margin="normal"
                        name="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                      />
                      {errors.submit && (
                        <Box sx={{ mt: 3 }}>
                          <FormHelperText error>
                            {errors.submit}
                          </FormHelperText>
                        </Box>
                      )}
                      <Box sx={{ mt: 2 }}>
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Submit
                        </Button>
                      </Box>
                      <Divider sx={{ my: 3 }} />
                      <Link
                        color="textSecondary"
                        component={RouterLink}
                        sx={{ mt: 0 }}
                        to={"/authentication/login"}
                        variant="body2"
                      >
                        BACK
                      </Link>
                    </form>
                  )}
                </Formik>

              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>

  );
};

export default ForgotPassForm;
