import React from 'react';
import merge from 'lodash/merge';
import { createTheme as cT, adaptV4Theme } from '@mui/material/styles';
import { ThemeProvider, responsiveFontSizes, StyledEngineProvider } from '@mui/material/styles';
import { THEMES } from '../constants';
import { lightShadows, darkShadows } from './shadows';

const baseOptions = {
  direction: 'ltr',
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: "18px"
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6'
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px'
        }
      }
    }
  },
  typography: {
    button: {
      fontWeight: 600
    },
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontWeight: 600,
      fontSize: '3.5rem'
    },
    h2: {
      fontWeight: 600,
      fontSize: '3rem'
    },
    h3: {
      fontWeight: 600,
      fontSize: '2.25rem'
    },
    h4: {
      fontWeight: 600,
      fontSize: '2rem'
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem'
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.125rem'
    },
    overline: {
      fontWeight: 600
    }
  }
};

const themesOptions = {
  [THEMES.LIGHT]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 0.86,
              color: '#42526e'
            }
          }
        }
      },
      MuiTypography: {
        variants: [
          {
            props: { variant: 'lineDataTop' },
            style: {
              color: "textPrimary",
              fontFamily: "Roboto",
              fontSize: '28px',
              textAlign: "center"
            }
          },
          {
            props: { variant: 'lineDataBottom' },
            style: {
              color: "textPrimary",
              fontFamily: "Roboto",
              fontSize: '13px',
              textAlign: "center"
            }
          },
          {
            props: { variant: 'smallData' },
            style: {
              color: "textPrimary",
              fontFamily: "Roboto",
              fontSize: '13px',
            }
          },
          {
            props: { variant: 'bigTitle' },
            style: {
              color: "textPrimary",
              fontFamily: "Roboto",
              fontWeight: 'bold',
              fontSize: '35px',
            }
          },
          {
            props: { variant: 'bigTitle2' },
            style: {
              color: "textPrimary",
              fontFamily: "Roboto",
              fontWeight: 'bold',
              fontSize: '28px',
            }
          }, {
            props: { variant: 'subTitle' },
            style: {
              color: "textPrimary",
              fontFamily: "Roboto",
              fontWeight: 'bold',
              fontSize: '20px',
            }
          }, {
            props: { variant: 'title' },
            style: {
              color: "textPrimary",
              fontFamily: "Roboto",
              fontWeight: 'bold',
              fontSize: '15px',
            }
          }, {
            props: { variant: 'data' },
            style: {
              color: "textPrimary",
              fontFamily: "Roboto",
              fontSize: '15px',
            }
          }
        ]
      },
      MuiButton: {
        variants: [
          {
            props: { variant: 'grayBorder' },
            style: {
              px: 2,
              py: 0.5,
              border: '2px solid #D5D5D5',
              borderRadius: "10px",
              color: "textPrimary",
              fontFamily: "Roboto",
              fontSize: '15px',
              fontWeight: 'normal',
              textAlign: "center"
            }
          },
        ]
      }
    },
    palette: {
      action: {
        active: '#6b778c'
      },
      background: {
        // default: '#f4f5f7',
        default: '#ffffff',
        paper: '#ffffff'
      },
      error: {
        contrastText: '#ffffff',
        main: '#f44336'
      },
      mode: 'light',
      primary: {
        contrastText: '#ffffff',
        main: '#0875E1'
      },
      info: {
        contrastText: '#ffffff',
        main: '#51A2F7'
      },
      success: {
        contrastText: '#ffffff',
        main: '#009104' //76C278
      },
      text: {
        primary: '#172b4d',
        secondary: '#6b778c'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      },
      secondary: {
        contrastText: '#9395A0',
        main: '#9395A0'
      }
    },
    shadows: lightShadows
  },
  [THEMES.DARK]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
          }
        }
      }
    },
    palette: {
      background: {
        default: '#171c24',
        paper: '#222b36'
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#f44336'
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: '#688eff'
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#ffffff',
        secondary: '#919eab'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      }
    },
    shadows: darkShadows
  },
  [THEMES.NATURE]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
          }
        }
      }
    },
    palette: {
      background: {
        default: '#1c2531',
        paper: '#293142'
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#f44336'
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: '#01ab56'
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#ffffff',
        secondary: '#919eab'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      }
    },
    shadows: darkShadows
  }
};

export const createTheme = (config = {}) => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.LIGHT];
  }

  const baseOptions1 = {
    direction: 'ltr',
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 0.86,
              color: '#42526e'
            }
          }
        }
      },
      MuiAvatar: {
        styleOverrides: {
          fallback: {
            height: '75%',
            width: '75%'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'none'
          }
        }
      },
      MuiCardHeader: {
        defaultProps: {
          titleTypographyProps: {
            variant: 'h6'
          }
        }
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            borderRadius: 3,
            overflow: 'hidden'
          }
        }
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: 'auto',
            marginRight: '16px'
          }
        }
      }
    },
    typography: {
      button: {
        fontWeight: 600
      },
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
      h1: {
        fontWeight: 600,
        fontSize: '3.5rem'
      },
      h2: {
        fontWeight: 600,
        fontSize: '3rem'
      },
      h3: {
        fontWeight: 600,
        fontSize: '2.25rem'
      },
      h4: {
        fontWeight: 600,
        fontSize: '2rem'
      },
      h5: {
        fontWeight: 600,
        fontSize: '1.5rem'
      },
      h6: {
        fontWeight: 600,
        fontSize: '1.125rem'
      },
      overline: {
        fontWeight: 600
      }
    },
    palette: {
      action: {
        active: '#6b778c'
      },
      background: {
        default: '#f4f5f7',
        paper: '#ffffff'
      },
      error: {
        contrastText: '#ffffff',
        main: '#f44336'
      },
      mode: 'light',
      primary: {
        contrastText: '#ffffff',
        main: '#262B40'
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#172b4d',
        secondary: '#6b778c'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      }
    },
    shadows: lightShadows,
    direction: config.direction
  };

  // let theme = cT(adaptV4Theme(baseOptions));
  // let theme = createTheme(merge({}, baseOptions, themeOptions, {
  //   ...(config.roundedCorners && {
  //     shape: {
  //       borderRadius: 16
  //     }
  //   })
  // }, {
  //   direction: config.direction
  // }));

  let theme = cT(merge({}, baseOptions, themeOptions, {
    ...(config.roundedCorners && {
      shape: {
        borderRadius: 16
      }
    })
  }, {
    direction: config.direction
  }));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
