import React, { Suspense, lazy } from 'react';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import PrintLayout from './components/dashboard/PrintLayout';

import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import MainLayout from './components/MainLayout';
// import UserManage from './views/User/UserManage';

import { useNavigate, useLocation } from 'react-router-dom';




function Loadable(Component) {
  const InjectedNavigate = function (props) {
    const navigate = useNavigate(props);
    const location = useLocation(props);
    return (<Suspense fallback={<LoadingScreen />}>
      <Component {...props} navigate={navigate} location={location} />
    </Suspense>)
  };
  return InjectedNavigate;
}


const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const ForgotPassForm = Loadable(lazy(() => import('./components/authentication/login/ForgotPassForm')));
const ForgotResetPass = Loadable(lazy(() => import('./components/authentication/login/ForgotResetPass')));

const Profile = Loadable(lazy(() => import('./views/User/Profile')));
const ResetPassword = Loadable(lazy(() => import('./views/User/ResetPassword')));

const CompanyDetails = Loadable(lazy(() => import('./views/Company/CompanyDetails')));
const CompanyManage = Loadable(lazy(() => import('./views/Company/CompanyManage')));

const UserList = Loadable(lazy(() => import('./views/User/UserList')));
const UserManage = Loadable(lazy(() => import('./views/User/UserManage')));

const CustomerList = Loadable(lazy(() => import('./views/Customer/CustomerList')));
const CustomerManage = Loadable(lazy(() => import('./views/Customer/CustomerManage')));

const CategoryList = Loadable(lazy(() => import('./views/Category/CategoryList')));
const CategoryManage = Loadable(lazy(() => import('./views/Category/CategoryManage')));
const InventoryLocationList = Loadable(lazy(() => import('./views/InventoryLocation/InventoryLocationList')));
const InventoryLocationManage = Loadable(lazy(() => import('./views/InventoryLocation/InventoryLocationManage')));

const CompanySiteManage = Loadable(lazy(() => import('./views/Company/SitesAndZones/CompanySiteManage')));
const CompanySiteDetails = Loadable(lazy(() => import('./views/Company/SitesAndZones/CompanySiteDetails')));

const PasswordSecurityForm = Loadable(lazy(() => import('./views/Security/PasswordSecurityForm')));

const SettingsCompanyDetails = Loadable(lazy(() => import('./views/Settings/SettingsCompanyDetails')));

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

const SiteShiftManage = Loadable(lazy(() => import('./views/Company/SitesAndZones/Shifts/SiteShiftManage')));

const UserDetails = Loadable(lazy(() => import("./views/User/UserDetails")));
const Dashboard = Loadable(lazy(() => import("./views/Dashboard/Dashboard")));


const InventoryList = Loadable(lazy(() => import("./views/Inventory/InventoryList")));
const InventoryManage = Loadable(lazy(() => import('./views/Inventory/InventoryManage')));

const WorkOrderList = Loadable(lazy(() => import("./views/WorkOrder/WorkOrderList")));
const WorkOrderManage = Loadable(lazy(() => import("./views/WorkOrder/WorkOrderManage")));
const PrintEquipmentPart = Loadable(lazy(() => import("./views/WorkOrder/PrintEquipmentPart")));

const RawMaterialInventoryList = Loadable(lazy(() => import("./views/RawMaterialsInventory/RawMaterialInventoryList")));
const RawMaterialInventoryManage = Loadable(lazy(() => import("./views/RawMaterialsInventory/RawMaterialInventoryManage")));

const UsedEuipmentsInventoryList = Loadable(lazy(() => import("./views/UsedEuipmentsInventory/UsedEuipmentsInventoryList")));
const UsedEuipmentsInventoryManage = Loadable(lazy(() => import("./views/UsedEuipmentsInventory/UsedEuipmentsInventoryManage")));

const GroupList = Loadable(lazy(() => import("./views/Groups/GroupList")));
const GroupManage = Loadable(lazy(() => import("./views/Groups/GroupManage")));
const PrintGroupPart = Loadable(lazy(() => import("./views/Groups/PrintGroupPart")));


const MobileView = Loadable(lazy(() => import("./views/MobileView/MobileView")));
const MobileViewManage = Loadable(lazy(() => import("./views/MobileView/MobileViewManage")));

const OperatorsConfiguration = Loadable(lazy(() => import("./views/OperatorConfiguration/OperatorsConfiguration")));
const OperatorGroupManage = Loadable(lazy(() => import("./views/OperatorConfiguration/OperatorGroupManage")));
const OperatorManage = Loadable(lazy(() => import("./views/OperatorConfiguration/OperatorManage")));

const TaskList = Loadable(lazy(() => import("./views/TaskConfiguration/TaskList")));
const TaskManage = Loadable(lazy(() => import("./views/TaskConfiguration/TaskManage")));

const Reporting = Loadable(lazy(() => import("./views/Reporting/Reporting")));

const routes = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'forgot-password',
        element: <ForgotPassForm />
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'reset-password',
        element: <ForgotResetPass />
      }
    ]
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/dashboard',
        element: <Dashboard />
      },
      {
        path: '/dashboard/profile',
        element: <Profile />
      },
      {
        path: '/dashboard/resetpassword',
        element: <ResetPassword />
      }
    ]
  },
  {
    path: 'inventory',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/inventory',
        element: <InventoryList />
      },
      {
        path: '/inventory/manage',
        element: <InventoryManage />
      },
      {
        path: '/inventory/profile',
        element: <Profile />
      },
      {
        path: '/inventory/resetpassword',
        element: <ResetPassword />
      }
    ]
  },
  {
    path: 'workOrder',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/workOrder',
        element: <WorkOrderList />
      },
      {
        path: '/workOrder/manage',
        element: <WorkOrderManage />
      }
    ]
  },
  {
    path: 'workOrder',
    element: (
      <AuthGuard>
        <PrintLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/workOrder/print',
        element: <PrintEquipmentPart />
      },
    ]
  },
  {
    path: 'reporting',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/reporting',
        element: <Reporting />
      },
    ]
  },
  {
    path: 'rawMaterials',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/rawMaterials',
        element: <RawMaterialInventoryList />
      },
      {
        path: '/rawMaterials/manage',
        element: <RawMaterialInventoryManage />
      },
      // {
      //   path: '/rawMaterials/groups',
      //   element: <GroupList />
      // },
      {
        path: '/rawMaterials/groups/manage',
        element: <GroupManage />
      },
      {
        path: '/rawMaterials/groups/print',
        element: <PrintGroupPart />
      },
    ]
  },
  {
    path: 'usedEquipments',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/usedEquipments',
        element: <UsedEuipmentsInventoryList />
      },
      {
        path: '/usedEquipments/manage',
        element: <UsedEuipmentsInventoryManage />
      },
    ]
  },
  {
    path: 'mobileView',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/mobileView',
        element: <MobileView />
      },
      {
        path: '/mobileView/manage',
        element: <MobileViewManage />
      },
    ]
  },
  {
    path: 'users',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/users',
        element: <UserList />
      },
      {
        path: '/users/manage',
        element: <UserManage />
      },
      {
        path: '/users/details',
        element: <UserDetails />
      }
    ]
  },
  {
    path: 'customers',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/customers',
        element: <CustomerList />
      },
      {
        path: '/customers/manage',
        element: <CustomerManage />
      },

    ]
  },
  {
    path: 'operators',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/operators',
        element: <OperatorsConfiguration />
      },
      {
        path: '/operators/group/manage',
        element: <OperatorGroupManage />
      },
      {
        path: '/operators/manage',
        element: <OperatorManage />
      }
    ]
  },
  {
    path: 'tasks',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/tasks',
        element: <TaskList />
      },
      {
        path: '/tasks/manage',
        element: <TaskManage />
      }
    ]
  },
  {
    path: 'categories',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/categories',
        element: <CategoryList />
      },
      {
        path: '/categories/manage',
        element: <CategoryManage />
      },
    ]
  },
  {
    path: 'location',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/location',
        element: <InventoryLocationList />
      },
      {
        path: '/location/manage',
        element: <InventoryLocationManage />
      },
    ]
  },
  // {
  //   path: 'loginlogs',
  //   element: (
  //     <AuthGuard>
  //       <DashboardLayout />
  //     </AuthGuard>
  //   ),
  //   children: [
  //     {
  //       path: '/loginlogs',
  //       element: <LoginLogs />
  //     },
  //   ]
  // },
  {
    path: 'passwordsecurity',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/passwordsecurity',
        element: <PasswordSecurityForm />
      },
    ]
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        // element: <FloorView />
        element: <Dashboard />
      },
    ]
  },
  {
    path: 'setting',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/setting',
        element: <SettingsCompanyDetails />
      },
    ]
  },
  {
    path: 'company',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/company',
        element: <CompanyDetails />
      },
      {
        path: '/company/manage',
        element: <CompanyManage />
      },
      {
        path: '/company/sites/details',
        element: <CompanySiteDetails />
      },
      {
        path: '/company/sites/manage',
        element: <CompanySiteManage />
      },

      {
        path: '/company/sites/shift/manage',
        element: <SiteShiftManage />
      },
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: (
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        ),
      },
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '/',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
