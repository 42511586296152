import { combineReducers } from '@reduxjs/toolkit';
// import { reducer as calendarReducer } from '../slices/calendar';

const initialState1 = {
};

const reducer1 = (state = initialState1, action) => {
  return state;
};
const rootReducer = combineReducers({
  "reducer1":reducer1
});

export default rootReducer;
