import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { Box, Button, FormHelperText, TextField, Divider, Link, Card, CardContent, Container, Typography } from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Logo from '../../../images/logo.png';
import axios from 'axios';
import { useSnackbar } from 'notistack'
const ForgotResetPass = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const isMountedRef = useIsMountedRef();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  const apiCalling = async (password, confirmPassword) => {
    var data = {
      newPassword: password,
      token: queryParams.token
    }
    var obj = await axios.post('/apis/CompanyLogin/changePassword/', data)
    return obj.data
  }

  return (
    <>
      <Helmet>
        <title>Forgot Password | Titan</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "#f5f3f2",
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Container
          maxWidth="sm"
          sx={{ py: '80px' }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 8
            }}
          >
            <img
              src={Logo}
              alt="Logo"
              height="70"
            />
          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3
                }}
              >
                <div>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                  >
                    Reset Password
                  </Typography>

                </div>

              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3
                }}
              >
                <Formik
                  initialValues={{
                    newPassword: '',
                    confirmPassword: '',
                    submit: null
                  }}
                  validationSchema={Yup
                    .object()
                    .shape({
                      newPassword: Yup
                        .string()
                        .required('Password is required'),
                      confirmPassword: Yup
                        .string()
                        .required('Confirm Password is required').oneOf([Yup.ref('newPassword')], 'Password does not same')
                    })}
                  onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                    try {
                      var data = await apiCalling(values.newPassword, values.confirmPassword);
                      if (data !== undefined && data.success && isMountedRef.current) {
                        props.navigate("/authentication/login")
                        enqueueSnackbar(data.message, {
                          anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                          },
                          variant: 'success'
                        });
                        setStatus({ success: true });
                        setSubmitting(false);
                      } else {
                        enqueueSnackbar(data.message, {
                          anchorOrigin: {
                            horizontal: 'right',
                            vertical: 'top'
                          },
                          variant: 'error'
                        });
                        setStatus({ success: false });
                        setErrors({ submit: data.message });
                        setSubmitting(false);
                      }

                    } catch (err) {
                      console.log("inCATCH");
                      console.error(err);
                      if (isMountedRef.current) {
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                      }
                    }
                  }}
                >
                  {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form
                      noValidate
                      onSubmit={handleSubmit}
                      {...props}
                    >

                      <TextField

                        error={Boolean(touched.newPassword && errors.newPassword)}
                        fullWidth
                        helperText={touched.newPassword && errors.newPassword}
                        label="New Password"
                        margin="normal"
                        name="newPassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.newPassword}
                        variant="outlined"
                      />
                      <TextField

                        error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                        fullWidth
                        helperText={touched.confirmPassword && errors.confirmPassword}
                        label="Confirm Password"
                        margin="normal"
                        name="confirmPassword"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        value={values.confirmPassword}
                        variant="outlined"
                      />
                      <Box sx={{ mt: 2 }}>
                        <Button
                          color="primary"
                          disabled={isSubmitting}
                          fullWidth
                          size="large"
                          type="submit"
                          variant="contained"
                        >
                          Verify
                        </Button>
                      </Box>
                      <Divider sx={{ my: 3 }} />
                      <Link
                        color="textSecondary"
                        component={RouterLink}
                        sx={{ mt: 0 }}
                        to={"/authentication/forgot-password"}
                        variant="body2"
                      >
                        BACK
                      </Link>
                    </form>
                  )}
                </Formik>

              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>

  );
};

export default ForgotResetPass;
