import React, { useEffect } from 'react';
import { SnackbarProvider, useSnackbar } from 'notistack'
import { Helmet } from 'react-helmet-async';
import { Box, Card, CardContent, Container, Divider, Link, Typography } from '@mui/material';
import {
  LoginJWT
} from '../../components/authentication/login';
import Logo from '../../images/logo.png';
import useAuth from '../../hooks/useAuth';
import gtm from '../../lib/gtm';

const platformIcons = {
  Amplify: '/static/icons/amplify.svg',
  Auth0: '/static/icons/auth0.svg',
  Firebase: '/static/icons/firebase.svg',
  JWT: '/static/icons/jwt.svg'
};

const Login = (props) => {
  const { platform, isExpired } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  useEffect(() => {
    if (isExpired) {
      enqueueSnackbar("Your Login is expired, Please login again", {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
    }
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <>
      <Helmet>
        <title>Login | Titan</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: "#f5f3f2",
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Container
          maxWidth="sm"
          sx={{ py: '80px' }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 8
            }}
          >
            <img
              src={Logo}
              alt="Logo"
              height="70"
            />
            {/* <RouterLink to="/">
              <Logo
                sx={{
                  height: 40,
                  width: 40
                }}
              />
            </RouterLink> */}
          </Box>
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3
                }}
              >
                <div>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                  >
                    Log in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    Log in on the internal platform
                  </Typography>
                </div>

              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3
                }}
              >
                <LoginJWT />
              </Box>
              {/* <Divider sx={{ my: 3 }} />
           
           
                <Link
                  color="textSecondary"
                  component={RouterLink}
                  sx={{ mt: 0 }}
                  to="/authentication/password-recovery"
                  variant="body2"
                >
                  Forgot password
                </Link>
            */}
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
